import { INavigationItem } from '../shared/modules/navigation/navigation.types';

export const navigationMenu: INavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    subtitle: 'System management',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        children: [
          {
            id: 'users.list',
            title: 'Users',
            type: 'basic',
            link: 'users/list',
          },
          {
            id: 'users.roles',
            title: 'Roles',
            type: 'basic',
            link: 'users/roles',
          },
          {
            id: 'users.permissions',
            title: 'Permissions',
            type: 'basic',
            link: 'users/permissions',
          }
        ]
      },
      {
        id: 'companies',
        title: 'Companies',
        type: 'basic',
        icon: 'apartment',
        link: 'companies',
        // badge: {
        //   title: '10',
        //   classes: 'px-2 bg-yellow-300 text-black rounded-full'
        // }
      },
      {
        id: 'talents',
        title: 'Talents',
        type: 'basic',
        icon: 'engineering',
        link: 'talents',
      },
      {
        id: 'jobs',
        title: 'Jobs',
        type: 'basic',
        icon: 'work',
        link: 'jobs',
      },
      {
        id: 'taxonomy',
        title: 'Taxonomy',
        type: 'basic',
        icon: 'dashboard',
        link: 'taxonomy',
      },
    ]
  },
];
